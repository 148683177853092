import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  prefix = 'bp';

  constructor(private meta: Meta) {
  }

  setMetadata(event, order) {
    if (order) {
      let timestamp = Math.floor((new Date()).getTime() / 1000);
      let orderID = order.id;

      let itineraries = [];
      let seats = {};
      order.flights.map((fl, idx) => {
        let leg = idx + 1;
        fl.segments.map(fs => {
          let od = fs.originDestination;
          itineraries.push([
            ['route', `${od.departure.airportCode}-${od.arrival.airportCode}`],
            ['departureDate', od.departure.date],
            ['departureTime', od.departure.time],
            ['arrivalDate', od.arrival.date],
            ['arrivalTime', od.arrival.time],
            ['flightNumber', fs.flightNumber],
            ['cabinDesignator', fs.detail.classOfService.cabinDesignator],
            ['classOfService', fs.detail.classOfService.code],
            ['segmentID', fs.segmentID],
            ['leg', leg]
          ]);
          if (fs.detail.classOfService.fare.basisCode) {
            itineraries[0].push(['fareBasisCode', fs.detail.classOfService.fare.basisCode]);
          }
          if (order.seats) {
            order.seats.map(seat => {
              if (fs.segmentID === seat.segment) {
                if (!seats[seat.refs]) {
                  seats[seat.refs] = [];
                }
                seats[seat.refs].push(fs.segmentID + '-' + seat.location.column + seat.location.row);
              }
            });
          }
        });
      });
      const itemReducer = (acc, cur) => {
        return `${acc}=${cur}`;
      };
      const listReducer = (acc, cur) => {
        return `${acc},${cur}`;
      };
      const nodesReducer = (acc, cur) => {
        return `${acc};${cur}`;
      };
      let itineraryText = itineraries
        .map(items => {
          let reducedItems = items.map(item => {
            return item.reduce(itemReducer);
          });
          return reducedItems.reduce(listReducer);
        })
        .reduce(nodesReducer);


      let paxData = {
        ADT: 0,
        YAD: 0,
        CHD: 0,
        INF: 0,
      };
      let passengersInfo = [];
      let passengersText = '';
      let address = '';
      let phone = '';
      let email = '';
      if (order.passengers) {
        order.passengers.map(p => {
          if (p.passengerType) {
            paxData[p.passengerType]++;
          }
          if (p.data) {
            let node = [
              ['name', p.data.name],
              ['surname', p.data.surname],
              ['title', p.data.title],
              ['gender', p.data.gender],
              ['birthdate', p.data.birthdate],
              ['passengerType', p.passengerType],
            ];
            if (p.document && p.document.documentType) {
              node.push(['documentType', p.document.documentType]);
            }
            if (p.document && p.document.documentID) {
              node.push(['documentID', p.document.documentID]);
            }
            if (p.document && p.document.expirationDate) {
              node.push(['documentExpiration', p.document.expirationDate]);
            }
            if (seats[p.travelerReference]) {
              node.push(['seats', seats[p.travelerReference].join(',')]);
            }
            if (p.data.fqtvInfo && p.data.fqtvInfo.account && p.data.fqtvInfo.account.number) {
              node.push(['ffNumber', p.data.fqtvInfo.account.number]);
            }
            let ticket = null;
            if (order.tickets) {
              order.tickets.map(t => {
                if (t.passengerRef === p.travelerReference) {
                  ticket = t;
                }
              });
            }
            if (ticket !== null) {
              node.push(['ticketNumber', ticket.ticketNumber]);
            }
            passengersInfo.push(node);
            if (p.data.email) {
              email = p.data.email;
            }
            if (p.data.phone && p.data.phone.number) {
              phone = '+' + p.data.phone.number;
            }
            if (p.data.address && (p.data.address.postalCode || p.data.address.countryCode || p.data.address.cityName || p.data.address.street)) {
              let addressItems = [];
              if (p.data.address.postalCode) {
                addressItems.push(p.data.address.postalCode);
              }
              if (p.data.address.countryCode) {
                addressItems.push(p.data.address.countryCode);
              }
              if (p.data.address.cityName) {
                addressItems.push(p.data.address.cityName);
              }
              if (p.data.address.street) {
                addressItems.push(p.data.address.street);
              }
              address = addressItems.join(' ');
            }
          }
        });

        passengersText = passengersInfo
          .map(items => {
            let reducedItems = items.map(item => {
              return item.reduce(itemReducer);
            });
            return reducedItems.reduce(listReducer);
          })
          .reduce(nodesReducer);
      }

      let priceInfo = [
        ['baseFare', order.price.provider.base],
        ['taxes', order.price.provider.tax.total],
        ['total', order.price.provider.total],
        ['currency', order.price.provider.currency],
      ];
      let priceText = priceInfo
        .map(item => {
          return item.reduce(itemReducer);
        })
        .reduce(listReducer);

      let contactInfo = [];
      if (email) {
        contactInfo.push(['email', email]);
      }
      if (phone) {
        contactInfo.push(['phone', phone]);
      }
      if (address) {
        contactInfo.push(['address', address]);
      }
      let contactText = '';
      if (contactInfo.length) {
        contactText = contactInfo
          .map(item => {
            return item.reduce(itemReducer);
          })
          .reduce(listReducer);
      }
      let orderPAX = [];
      if (paxData.ADT) {
        orderPAX.push(`${paxData.ADT}ADT`);
      }
      if (paxData.YAD) {
        orderPAX.push(`${paxData.YAD}YAD`);
      }
      if (paxData.CHD) {
        orderPAX.push(`${paxData.CHD}CHD`);
      }
      if (paxData.INF) {
        orderPAX.push(`${paxData.INF}INF`);
      }
      this.resetMeta();

      this.meta.addTag({name: `${this.prefix}:timestamp`, content: timestamp.toString()}, true);
      this.meta.addTag({name: `${this.prefix}:event`, content: event}, true);
      this.meta.addTag({name: `${this.prefix}:orderID`, content: orderID}, true);
      this.meta.addTag({name: `${this.prefix}:orderItinerary`, content: itineraryText}, true);
      this.meta.addTag({name: `${this.prefix}:orderPassengers`, content: passengersText}, true);
      this.meta.addTag({name: `${this.prefix}:orderPrice`, content: priceText}, true);
      if (contactText) {
        this.meta.addTag({name: `${this.prefix}:orderContact`, content: contactText}, true);
      }
      this.meta.addTag({name: `${this.prefix}:orderPAX`, content: orderPAX.join(',')}, true);
      if (order.pnr) {
        this.meta.addTag({name: `${this.prefix}:orderPNR`, content: order.pnr}, true);
      }
      if (order.bookingDate) {
        this.meta.addTag({name: `${this.prefix}:orderBookingDate`, content: order.bookingDate}, true);
      }
    }
  }

  resetMeta() {
    this.meta.removeTag(`name="${this.prefix}:timestamp"`);
    this.meta.removeTag(`name="${this.prefix}:event"`);
    this.meta.removeTag(`name="${this.prefix}:orderID"`);
    this.meta.removeTag(`name="${this.prefix}:orderItinerary"`);
    this.meta.removeTag(`name="${this.prefix}:orderPassengers"`);
    this.meta.removeTag(`name="${this.prefix}:orderPrice"`);
    this.meta.removeTag(`name="${this.prefix}:orderContact"`);
    this.meta.removeTag(`name="${this.prefix}:orderPAX"`);
    this.meta.removeTag(`name="${this.prefix}:orderPNR"`);
    this.meta.removeTag(`name="${this.prefix}:orderBookingDate"`);
  }
}
